@import 'components/mixins.scss';

@import 'scss/main';

:global(.ant-layout) {
  @include respond-to('mobile') {
    .header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      z-index: 10;
    }

    .header + * {
      margin-top: 50px;
    }
  }
}
