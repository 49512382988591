@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';

// Responsive breakpoint
@mixin respond-to($breakpoint) {
  $raw-query: map.get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      meta.type-of($raw-query) == 'string',
      string.unquote($raw-query),
      meta.inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' +
      'Please make sure it is defined in `$breakpoints` map.';
  }
}
