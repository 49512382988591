@use 'sass:list';

@import 'scss/main';

@mixin nth-label($n, $offset) {
  td:nth-child(#{$n + $offset}):before {
    content: var(--label#{$n}, '');
  }
}

@mixin nth-label-style($n) {
  td:nth-child(#{$n}),
  [class~='nine-list-item-column']:nth-child(#{$n}) {
    @content;
  }
}

@mixin mobile-table($colCnt: 5, $offset: 0) {
  [class~='nine-column-title'] {
    display: none;
  }

  @include respond-to('mobile') {
    table,
    tbody {
      display: block;
    }

    colgroup {
      display: none;
    }

    thead {
      display: none;
    }

    tbody {
      tr:nth-child(1) {
        display: none;
      }

      // tr:nth-child(2) {
      //   border-top: 1px solid #e4e9f0;
      // }

      tr:nth-child(n + 2) {
        position: relative;
        display: block;
        border-bottom: 1px solid #e4e9f0;
        padding: 6px 0;

        td {
          display: block;
          text-align: right;
          border: none;
          padding: 4px 10px;

          // &:before {
          //   content: '';
          //   float: left;
          //   font-weight: bold;
          // }
        }

        // @for $i from 1 through $colCnt {
        //   @include nth-label($i, $offset);
        // }

        td[colspan] {
          &:before {
            content: none;
          }

          [class~='ant-table-expanded-row-fixed'] {
            max-width: 100%;
          }
        }
      }
    }

    [class~='nine-column-title'] {
      display: block;
      float: left;
      font-weight: bold;
      text-align: left;
    }

    [class~='nine-column-value'] {
      text-align: right;
    }

    @content;
  }
}

// sass파일에서 @import로 포함될 때와 js파일에서 import로 포함될 때 class selector에 대한 처리가 각각 다르기 때문에
// 동작의 통일을 위해 attribute selector '~='을 사용함.
@mixin nine-mobile-table-def($selectors, $colCnt: 5, $offset: 0) {
  #{$selectors}[class~='useMobileMode'] {
    @include mobile-table($colCnt, $offset) {
      @content;
    }
  }
}

@mixin nine-mobile-table($selectors) {
  #{$selectors}[class~='useMobileMode'] {
    @include respond-to('mobile') {
      @content;
    }
  }
}

@mixin nine-mobile-foldable-table($selectors, $main-columns: 1) {
  #{$selectors}[class~='useMobileMode'][class~='foldable'] {
    @include respond-to('mobile') {
      tr:nth-child(n + 2),
      [class~='ant-list-item'] {
        position: relative;

        &:after {
          /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
          font-family: Feather;
          content: '\e92e';
          text-align: center;

          position: absolute;
          width: var(--foldicon-width, 24px);
          height: var(--foldicon-height, 24px);
          top: var(--foldicon-top, 9px);
          right: var(--foldicon-right, 4px);
          border: solid 1px gray;
          border-radius: var(--foldicon-radius, 12px);
          font-size: var(--foldicon-fontsize, 12px);
        }

        &[class~='unfold']:after {
          /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
          font-family: Feather;
          content: '\e931';
        }
      }

      td,
      [class~='nine-list-item-column'] {
        display: none;
      }

      @each $main-column in $main-columns {
        td:nth-child(#{$main-column}),
        [class~='nine-list-item-column']:nth-child(#{$main-column}) {
          display: block;
        }
      }

      $first-column: list.nth($main-columns, 1);

      td:nth-child(#{$first-column}),
      [class~='nine-list-item-column']:nth-child(#{$first-column}) {
        [class~='nine-column-value'] {
          padding-right: var(--foldicon-padding, 24px);
        }
      }

      tr[class~='unfold']:nth-child(n + 2) td,
      [class~='ant-list-item'][class~='unfold'] [class~='nine-list-item-column'] {
        display: block;

        &:nth-child(1) [class~='nine-column-value'] {
          padding-right: var(--foldicon-padding, 24px);
        }
      }

      @content;
    }
  }
}
