@import 'gql/ninegqlUI/mixins';

@include nine-mobile-table-def('', 10, 0);

.nine-list-item,
.nine-list-header {
  display: flex;
  flex: auto;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.nine-list-item-column,
.nine-list-header-column {
  flex: 1 1 var(--item-width, 0);
  text-align: var(--item-align, left);
  display: block;
  padding: 16px;

  .nine-column-title {
    display: none;
  }
}

.nine-list {
  .ant-list-header {
    background: #f0f2f4;
    padding: 0;
  }

  .ant-list-item {
    padding: 0;
  }

  .more-button-container {
    text-align: center;
  }
}

.useMobileMode {
  @include respond-to('mobile') {
    .nine-list-item {
      display: block;
      padding: 8px;
    }

    .nine-list-header {
      display: none;
    }

    .nine-list-item-column,
    .nine-list-header-column {
      padding: 4px;
      text-align: var(--item-align, right);

      .nine-column-title {
        display: block;
        float: left;
        font-weight: bold;
      }
    }

    &.nine-list {
      .ant-list-header {
        background: #f0f2f4;
        padding: 0;
        border-bottom: 0;
      }

      .ant-list-item {
        padding: 0;
      }
    }

    .nine-column-title {
      text-align: left;
    }
  }
}

.ant-descriptions-item-content pre {
  font-family: var(--kit-font-family);
  font-size: 14px;
  color: #595c97;
  white-space: pre-wrap;
  margin-bottom: 0;
  padding: 1px 0;
}

.more-button-container {
  margin-top: 20px;
  text-align: center;
}
