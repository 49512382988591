@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  "CORE" STYLES */

:root {
  --kit-color-primary: #4b7cf3;
  --kit-font-family: 'Mukta', sans-serif;
}

html {
  font-size: 15px;
  @media (max-width: $sm-max-width) {
    font-size: 14px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;

  @include transition-color;

  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base-font-family;

  &:not([disabled]) {
    color: $text;
  }
}

button,
input {
  box-shadow: none !important;
  outline: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

svg {
  vertical-align: initial;
  overflow: auto;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: $gray-2;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}

// dark theme
[data-kit-theme='dark'] {
  body {
    background: $dark-gray-5;
    color: $dark-gray-1;
  }

  .badge-example {
    background: $dark-gray-4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-gray-1;
  }

  a {
    color: $dark-gray-1;

    &:hover,
    &:active,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }
}
